/* eslint-disable import/prefer-default-export */
import { SITE_BASE_PATH, TATA_TEL_CALL_API_AUTH_TOKEN, USER_ROLE } from '../constant';
import moment from 'moment';

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('kdksoft-token')}`,
  }
});

export const getFileConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('kdksoft-token')}`,
  }
});

export const getTaTaTelCallConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${TATA_TEL_CALL_API_AUTH_TOKEN}`,
  }
});

export const getConfigForDeleteVerb = (data) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('kdksoft-token')}`,
  }, data
});

export function isTokenValid() {
  const token = localStorage.getItem('kdksoft-token');
  if (!token || token === 'null') { saveRedirectUrl(); return false; }
  //const decodedToken = jwt.decode(token, { complete: true });
  //if (!decodedToken || !decodedToken.payload) { saveRedirectUrl(); return false; }
  return true;
}

export function saveRedirectUrl() {
  const loginPaths = [
    `${SITE_BASE_PATH}login`,
    `${SITE_BASE_PATH}signup`,
    `${SITE_BASE_PATH}verify`,
    `${SITE_BASE_PATH}forgetpass`,
    `${SITE_BASE_PATH}resetpass`,
  ];

  if (!loginPaths.includes(window.location.pathname)) {
    let data = { path: window.location.pathname + window.location.search, time: moment().add(1, 'hours').unix() };
    localStorage.setItem('redirect-url', JSON.stringify(data));
  }
}


export function getRedirectUrl() {
  let redirect_url = localStorage.getItem('redirect-url');
  if (!redirect_url) return false;

  redirect_url = JSON.parse(redirect_url);

  if (redirect_url.time > moment.unix()) return false;
  return redirect_url.path;

}

export function removeRedirectUrl() {
  localStorage.removeItem('redirect-url');
}


export function getItems() {

  let items = [];

  const location = window.location.pathname;

  if (location.indexOf('signup') === -1) {

    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN', 'SALES', 'RENEWAL', 'CUSTOMER_CARE', 'PARTNER', 'MIS', 'LICENSE_ADMIN', 'FINANCE'].includes(USER_ROLE)) {
      //For Dashboard
      items.push({ name: 'Dashboard', title: 'Dashboard', icon: 'k-icon k-i-graph', route: `${SITE_BASE_PATH}dashboard/new`, selectitems: 'dashboard' });
      items.push({ separator: true },);
    }
    
    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN', 'SALES', 'RENEWAL', 'CUSTOMER_CARE', 'PARTNER',].includes(USER_ROLE)) {
      //For Coupon item
      items.push({ name: 'Coupons', title: 'Coupons', icon: 'k-icon k-i-cart', route: `${SITE_BASE_PATH}coupones/bulk`, selectitems: 'coupones' });
      items.push({ separator: true },);
    }  

    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN', 'MIS'].includes(USER_ROLE)) {
      //For Plans
      items.push({ name: 'Plans', title: 'Plans', icon: 'k-i-folder-open', route: `${SITE_BASE_PATH}plan/published/list`, selectitems: "published" });
      items.push({ separator: true },);
    }

    if (['SALES', 'RENEWAL', 'PARTNER'].includes(USER_ROLE)) {
      //For Plans
      items.push({ name: 'Plans', title: 'Plans', icon: 'k-i-folder-open', route: `${SITE_BASE_PATH}plan/specific/published/list`, selectitems: "published" });
      items.push({ separator: true },);
    }

    if ([ 'SUPER_ADMIN', 'CUSTOMER_CARE'].includes(USER_ROLE)) {
      //For Customer Timeline
      items.push({ name: 'Customer-Timeline', title: 'Customer Timeline', icon: 'k-icon k-i-clock', route: `${SITE_BASE_PATH}customers/timeline`, selectitems: 'timeline' });
      items.push({ separator: true },);
    }   

    if ([ 'SUPER_ADMIN', 'LICENSE_ADMIN','PARTNER', 'SALES', 'RENEWAL'].includes(USER_ROLE)) {
      //ForExtend Validity
      items.push({ name: 'Extend-Validity', title: 'Extend Validity', icon: 'k-icon k-i-hyperlink-open', route: `${SITE_BASE_PATH}customers/extend-validity`, selectitems: 'extend-validity' });
      items.push({ separator: true },);
    } 
    
    if ([ 'SUPER_ADMIN', 'LICENSE_ADMIN'].includes(USER_ROLE)) {
      //For Change Org Type
      items.push({ name: 'Change-Org-Type', title: 'Change Org Type', icon: 'k-i-filter-add-expression', route: `${SITE_BASE_PATH}customers/change-org-type`, selectitems: 'change-org-type' });
      items.push({ separator: true },);
    } 

    if ([ 'SUPER_ADMIN', 'LICENSE_ADMIN'].includes(USER_ROLE)) {
      //For Revoke Plan
      items.push({ name: 'Revoke-Plan', title: 'Revoke Plan', icon: 'k-icon k-i-undo', route: `${SITE_BASE_PATH}customers/revoke-plan`, selectitems: 'revoke-plan' });
      items.push({ separator: true },);
    } 
    
    if (['PARTNER','SALES'].includes(USER_ROLE)) {
      //For Lock a Trial Customer
      items.push({ name: 'Lock a Trial Customer', title: 'Lock a Trial Customer', icon: 'k-icon k-i-lock', route: `${SITE_BASE_PATH}customers/lock_customer`, selectitems: 'lock_customer' });
      items.push({ separator: true },);
    }
    if (['SUPER_ADMIN', 'LICENSE_ADMIN'].includes(USER_ROLE)) {
      //For Lock a Trial Customer
      items.push({ name: 'Lock a Trial Customer', title: 'Lock a Trial Customer', icon: 'k-icon k-i-lock', route: `${SITE_BASE_PATH}customers/view_lock_customer`, selectitems: 'view_lock_customer' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN','PARTNER','RENEWAL'].includes(USER_ROLE)) {
      //For Lock In Period
      items.push({ name: 'Lock In Period', title: 'Lock In Period', icon: 'k-icon k-i-unlock', route: `${SITE_BASE_PATH}customers/lock-in-period-expiring`, selectitems: 'customers' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'FINANCE'].includes(USER_ROLE)) {
      //For Finance  
      items.push({ name: 'License Approvals', title: 'License Approvals', icon: 'k-icon k-i-file-flash', route: `${SITE_BASE_PATH}finance/details`, selectitems: 'finance' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'FINANCE'].includes(USER_ROLE)) {
      //For Finance  
      items.push({ name: 'Pending Partner Payments', title: 'Pending Partner Payments', icon: 'k-i-book', route: `${SITE_BASE_PATH}partner-payments/pending`, selectitems: 'payments' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'FINANCE'].includes(USER_ROLE)) {
      //For Finance  
      items.push({ name: 'Credit / Debit Note Issued to Partner', title: 'Credit / Debit Note Issued to Partner', icon: 'k-i-track-changes-accept', route: `${SITE_BASE_PATH}partner-credit-debit-notes`, selectitems: 'notes' });
      items.push({ separator: true },);
    }

    if (['PARTNER', 'PARTNER_ADMIN', 'MIS'].includes(USER_ROLE)) {
      //For Finance  
      items.push({ name: 'License Approvals', title: 'License Approvals', icon: 'k-icon k-i-file-flash', route: `${SITE_BASE_PATH}finance/info/pending`, selectitems: 'finance' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN', 'MIS'].includes(USER_ROLE)) {
      //For Margin Profile
      items.push({ name: 'Margin Profile', title: 'Margin Profile', icon: 'k-icon k-i-percent', route: `${SITE_BASE_PATH}margin/profile/product`, selectitems: 'margin' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN', 'LICENSE_ADMIN', 'FINANCE'].includes(USER_ROLE)) {
      //For SD users
      items.push({ name: 'SD Users', title: 'SD Users', icon: 'k-icon k-i-file-mdb', route: `${SITE_BASE_PATH}admin`, selectitems: 'admin' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN', 'PARTNER_ADMIN'].includes(USER_ROLE)) {
      items.push({ name: 'Price Calculator', title: 'Price Calculator', icon: 'k-icon k-i-calculator', route: `${SITE_BASE_PATH}price/calculator`, selectitems: 'price' });
      items.push({ separator: true },);
    }
    
    if (['SUPER_ADMIN', 'ADMIN', 'SALES','RENEWAL','PARTNER', 'CUSTOMER_CARE', 'FINANCE'].includes(USER_ROLE)) {
      //For Claim
      items.push({ name: 'Claim', title: 'Claim', icon: 'k-icon k-i-save', route: `${SITE_BASE_PATH}claim/pending`, selectitems: 'claim' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN'].includes(USER_ROLE)) {
      //Org Chart
      items.push({ name: 'Org Chart', title: 'Org Chart', icon:'k-icon k-i-forward k-rotate-270', route: `${SITE_BASE_PATH}org_chart`, selectitems: 'org_chart' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN'].includes(USER_ROLE)) {
      //For Discount Approval Hierarchy
      items.push({ name: 'Discount Approval Hierarchy', title: 'Discount Approval Hierarchy',icon:'k-icon k-i-flip-horizontal' , route: `${SITE_BASE_PATH}discount/approval_hierarchy`, selectitems: 'discount' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'PARTNER', 'FINANCE'].includes(USER_ROLE)) {
      //For Coupon item
      items.push({ name: 'Ledger-Draft-Statement', title: 'Ledger Draft Statement', icon: 'k-icon k-i-book', route: `${SITE_BASE_PATH}ledger/draft-statement`, selectitems: 'ledger' });
      items.push({ separator: true },);
    }

    if (['SUPER_ADMIN', 'ADMIN'].includes(USER_ROLE)) {
      //Authorize user
      items.push({ name: 'Authorize User', title: 'Authorize User', icon:'k-icon k-i-myspace-box', route: `${SITE_BASE_PATH}authorize-user`, selectitems: 'authorize_user' });
      items.push({ separator: true },);
    }

    if (['FINANCE'].includes(USER_ROLE)) {
      //Cancel Invoice and revoke plan
      items.push({ name: 'Recall-Subscription', title: 'Recall Subscription', icon: 'k-i-track-changes-reject-all', route: `${SITE_BASE_PATH}recall-subscription`, selectitems: 'recall-subscription' });
      items.push({ separator: true },);
    }

    //For Logout 
    items.push({ name: 'LogOut', title: 'LogOut', icon: 'k-i-logout', route: `${SITE_BASE_PATH}logout`, selectitems: 'logout' });
    items.push({ separator: true },);

  }

  return items;
}

